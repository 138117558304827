* {
  margin: 0;
  padding: 0;
}

// Root
html {
  font-size: 62.5%;
}

html,
body{
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get($color-map, primary);
  min-height: 100vh;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

hr {
  border-bottom: 0;
  border-color: map-get($color-map, secondary);

  @include when-inside("footer") {
    border-color: map-get($color-map, terziary);
  }
}

// LoVe-HAte
a:link,
a:visited {
  color: map-get($color-map, primary);
  text-decoration: none;
  transition: all 250ms;
}

a:hover,
a:active {
  color: darken(map-get($color-map, primary), 20%)
}


// Form Base
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  appearance: none;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  outline: 0;
}

label,
legend {
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}


// Collezione FontSize + LineHeight + LetterSpacing
@each $macro, $macroMap in $typeSetting {
  .size-#{$macro},
  %size-#{$macro} {
    @each $prop, $val in $macroMap {
        @if($prop) == 'default'{
          @each $prop1, $val1 in $val {
            #{$prop1}:#{$val1}
          }
        } @else {
          @media screen and (min-width: #{$prop+"px"}) {
            @each $prop1, $val1 in $val {
              #{$prop1}:#{$val1}
            }
          }
        }
    }
  }
}

// colori
@each $color-key, $color-val in $color-map {
  .color-#{$color-key},
  %color-#{$color-key} {
    color: #{$color-val};
  }
  .bg-#{$color-key},
  %color-#{$color-key} {
    background-color: #{$color-val};
  }
}

// Regole generiche dei componenti
.livedit {
  @extend %clearfix; 
  position: relative;
}

.debug-off pre {
  display: none
}

.standby {
  @media screen and (max-width: 992px) {
    overflow: hidden;
  }
}

.bookPreview {
  display: flex;
  
  &__thumb {
    flex-basis: 50%;
    margin-right: .8rem;
  }

  &__thumbSource {
    max-width: 100%;
    display: block;
  }

  &__info {}

  &__title {
    @extend %size-normal;
    font-family: map-get($family, primary);
    font-weight: 700;
    font-style: italic;
  }

  &__year {
    @extend %size-normal;
  }

  &__author {
    @extend %size-small;
  }

}

.map {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;

  &__iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
    border: 0;
  }
}