.masonry {

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;

    @include respond-to("s-768") {
      grid-template-columns: repeat(3, 1fr);  
    }
  }
  
  &__item {
    display: flex;
    min-height: 320px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    grid-row: span 1;
    grid-column: span 1;
     
    &--size22 {
      @include respond-to("s-768") {
        grid-row: span 2;
        grid-column: span 2;
      }
    }
     
    &--size12 {
      @include respond-to("s-768") {
        grid-row: span 2;
      }
    }
     
    &--size21 {
      @include respond-to("s-768") {
        grid-column: span 2;
      }
    }

    &--size31 {
      @include respond-to("s-768") {
        grid-column: span 3;
      }
    }
  }

  &__anchor {
    display: flex;
    align-items: flex-end;
    width: 100%;
    
    &--null {
      pointer-events: none;
    } 
  }

  &__info {
    background: white;
    padding: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    @extend %size-medium;
  }

  &__subtitle {
    line-height: 1.6rem;
  }

}