.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: map-get($color-map, white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  &--autohide {
    animation: cssAnimation 0s ease-in 5s forwards;
    animation-fill-mode: forwards;
    transition: all 200ms;
  }

  &--admin {
    background: rgba(map-get($color-map, white), .9);
    top: 3px;
    left: 3px;
    bottom: 3px;
    right: 3px;
    z-index: 20;
    border: 1px dashed rgba(map-get($color-map, gray), .30);
    cursor: pointer;
    opacity: 0;
    transition: all 250ms;

    @at-root .admin .livedit:hover & { // admin mode
      top:-3px;
      left: -3px;
      bottom: -3px;
      right:-3px;
      opacity: 1;
    }
  }
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}