.progressbar {
    display: flex;
    background-color: map-get($color-map, gallery);
    border-radius: 3px;
    position: relative;

    &--cart {
        margin-bottom: 30px;
        margin-top: 80px;
    }

    @keyframes progress-bar-stripes {
      from { background-position: 25px 0; }
      to { background-position: 0 0; }
    }

    &__line {
      display: flex;
        height: 25px;
        width: 0;
        background-color: map-get($color-map, primary);
        border-radius: 3px;
        transition: width 600ms;
        animation: progress-bar-stripes 1s linear infinite;
        background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
        background-size: 25px 25px;
    }

    &__step {
        position: absolute;
        background-color: map-get($color-map, gallery);
        bottom: 44px;
        margin: 0;
        padding: 2px 5px;
        border-radius: 2px;
        margin-left: -7px;
        text-align: center;
        line-height: 1.5rem;

        &--1 {left: 0%}
        &--2 {left: 33.333333%}
        &--3 {right:33.333333%;margin-right: -7px}
        &--4 {right:0;margin-right: -7px}
        

        // Arrow down
        &::after {
            content: '';
            width: 25px;
            height: 25px;
            background: map-get($color-map, gallery);
            position: absolute;
            bottom: -44px;
            border-radius: 100%;
            left: 0;
            /*
            content: '';
            border: solid #efefef;
            border-width: 6px;
            border-color: rgba(136, 183, 213, 0);
            border-top-color: #efefef;
            position: absolute;
            top: 100%;
            left: 3px;
            */
        }

        &::before {
            content: '';
            width: 1px;
            height: 25px;
            background: map-get($color-map, gallery);
            position: absolute;
            left: 12px;
            top: 100%;
        }

        @at-root .progressbar__step--3::after {left:auto;right:0}
        @at-root .progressbar__step--4::after {left:auto;right:0}
        @at-root .progressbar__step--3::before {left:auto;right:12px}
        @at-root .progressbar__step--4::before {left:auto;right:12px}
    }
}