.badge {
  width: 17px;
  height: 17px;
  background-color: map-get($color-map, action);
  color: map-get($color-map, white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid darken(map-get($color-map, action), 20%);
  position: absolute;
  top: -4px;
  right: -7px;
  line-height: 0;

  &:empty {
    display: none;
  }
}