.slick {
  $slick-root: &;
  
  &-slider {
    position: relative;
    display: block;
    user-select: none;
    touch-action: pan-y;
  }

  &--fluid {
    height: 100vh;
  }

  &-claim {
    position: absolute;
    left: 50%;
    bottom: 10%;
    width: 90%;
    height: auto;
    border-left: 0px solid white;
    padding-left: 15px;
    transition: border-width 400ms 400ms;
    transform: translateX(-50%);
    
    @include respond-to("s-768") {
      width: auto;
    }

    #{$slick-root}-active & {
      border-width: 6px;
    }

    h2,
    p,
    button {
      opacity: 0;
      position: relative;
      left: -25px;
      transition: all 800ms 600ms;

      #{$slick-root}-active & {
        left: 0;
        opacity: 1;
      }
    }

    

    h2 {
      font-size: 18px;
      line-height: 1;

      @include respond-to("s-768") {
        font-size: 30px;
      }
    }

    p {
      max-width: 570px;
    }

    .tag {
      @extend %size-big;
      margin-right: 7px;
      float: left;
      letter-spacing: -1px;
      padding: 0px 6px;

      & + .txt {
        overflow: hidden;
        display: block;
      }
    }
  }

  &-track,
  &-list {
    transform: translate3d(0, 0, 0);

    #{$slick-root}--fluid & {  
      height: 100%;
    }
  }

  &-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    :focus {
      outline: none;
    }

    .dragging {
      cursor: hand;
    }
  }

  &-slide {
    display: none;
    float: left;
    min-height: 1px;

    #{$slick-root}--fluid & {  
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left bottom;

      div {
        height: inherit;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      
    }

    #{$slick-root}--showcase & {
      padding: 0 1.5rem 0 0;
      max-height: 50rem;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }

      & > div {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        height: calc(100% - 5px);
      }

    }

    #{$slick-root}--zoom & {
      max-height: 40rem;
      margin-bottom: 1rem;
      background: white;

      img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        max-height: 40rem;
      }
    }

    #{$slick-root}--zoom-nav & {
      margin: 0 .5rem;
      background: #fff;
      box-shadow: 0 1px 5px 0 rgba(57,15,47,.09);
      border: 1px solid #eee;
      position: relative;
      overflow: hidden;
      height: 150px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    &:last-child{
      padding-right:0;
    }

    

    #{$slick-root}-initialized & {
      display: block;
    }

  }

  &-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    transition: left 400ms;
    
    #{$slick-root}--showcase & {
      display: flex;
    }

    #{$slick-root}-move & {
      left: -5rem;
    }

    #{$slick-root}-loading & {
      visibility: hidden;
    }

    :before,
    :after {
      display: table;
      content: '';
    }

    :after {
      clear: both;
    }
  }

  &-arrow {
    position: absolute;
    top: 50%;
    width: 71px;
    height: 71px;
    box-shadow: 0 0 13px 0 rgba(38, 38, 38, 0.2);
    background-color: #ffffff;
    margin-top: -35px;
    cursor: pointer;
    color: map-get($map: $color-map, $key: primary);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      width: 71px;
      height: 71px;
    }

    // su dispositivi touch non sono presenti
    .touch & {
      display: none!important;
    }

    &-prev {
      left: -35px;
    }

    &-next {
      right: -20px;
    }
  }

  &-dots{
    text-align: center;

    #{$slick-root}--fluid & {  
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0;
      background: map-get($map: $color-map, $key: pale-grey);
      padding: 5px 20px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      display: flex;
      transform: translateX(-50%);
    }

    li {
      position: relative;
      display: inline-block;
      width: 15px;
      height: 15px;
      margin: 0 2px;
      padding: 0;
      cursor: pointer;

      #{$slick-root}--fluid & {
        border-radius: 10px;
        height: 8px;
        width: auto;
        min-width: 12px;
      }

      &.slick-active button {
        background: darken(map-get($color-map, primary), 15%);

        #{$slick-root}--fluid & {
          width: 24px;
          background: map-get($map: $color-map, $key: terziary);
          border-radius: 10px;
          opacity: 1;
        }
      }
    }

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 10px;
      height: 10px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      outline: none;
      border-radius: 100%;
      background: map-get($color-map, primary);
      border: 1px solid darken(map-get($color-map, primary), 15%);

      #{$slick-root}--fluid & {  
        background: map-get($map: $color-map, $key: terziary);
        opacity: .2;
        border-color: transparent;
        padding: 0;
        height: 8px;
        width: 8px;
      }
    }
  }

  &__toZoom {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 35px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px 0 0;
    background: rgba(255,255,255,.9);
    left: 50%;
    transform: translate(-50%);
    
    @include respond-to("s-768") {
      left: auto;
      right: 0;
    }

    & > svg {
      width: 30px;
      height: 30px;
      fill: map-get($color-map, terziary );
    }
  }


}