.courseDetail {

  $gap: 36px;
  position: relative;
  z-index: 2;

  @include respond-to("s-768") {
    margin-top: -240px;
  }

  &__media {
    display: flex;
    flex-basis: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-bottom: 1px solid rgba(map-get($color-map, brown-grey), .5);

    @include respond-to("s-768") {
      min-height: 385px;
    }

    &-top {
      display: flex;
      flex: 1;

      @include respond-to("s-768") {
        padding: $gap;
      }
    }

    &-bottom {
      padding: $gap 0;

      @include respond-to("s-768") {
        padding: 0 ($gap * 2) $gap;
      }
      
    }
    
  }

  &__date {
    flex-shrink: 0;
    margin-right: 24px;
  }

  &__description,
  &__extraInfo {}

  &__extraInfo {
    @include respond-to("s-768") {
      padding-left: 140px
    }
  }

}
