$typeSetting: (
  'huge': (
    'default': (
      'font-size': '2.4rem',
      'line-height': '1'
    ),
    '768': (
      'font-size': '4rem'
    )
  ),
  'big': (
    'default': (
      'font-size': '2rem',
      'line-height': '1'
    ),
    '768': (
      'font-size': '3rem'
    )
  ),
  'large': (
    'default': (
      'font-size': '2.6rem',
      'line-height': '1'
    )
  ),
  'medium-large': (
    'default': (
      'font-size': '2rem',
      'line-height': '1'
    ),
    '768': (
      'font-size': '2.4rem'
    )
    
  ),
  'medium': (
    'default': (
      'font-size': '2rem',
      'line-height':'2.6rem'
    )
  ),
  'normal': ( 
    'default': (
      'font-size': '1.8rem',
      'line-height':'2.6rem'
    )
  ),
  'base': (
    'default': (
      'font-size': '1.6rem',
      'line-height':'2.6rem'
    )
  ),
  'small': (
    'default': (
      'font-size': '1.4rem',
      'line-height':'1.8rem'
    )
  ),
  'micro': (
    'default': (
      'font-size': '1.2rem'
    )
  )
);

$family: (
  'primary': 'Raleway'
);