.logo {
  margin: 0;
  display: flex;
  align-items: center;

  // quando si trova in un header verticale
  @include when-inside('header', '--vertical') {  
    @include respond-to('s-768') {
      margin: 0 0 1.5rem;
    }
  }

  &__img {
    width: 100%;
    max-width: 20rem;
  }

  &__text {
    @extend %size-medium;
    white-space: nowrap;
    color: map-get($color-map, white);

    @at-root .header--highlight & {
      color: map-get($map: $color-map, $key: primary);
    }
    
    @at-root .header--plain & {
      color: map-get($map: $color-map, $key: primary);
    }

    &-sub {
      font-size: 90%;
      display: block;
      text-align: center;
      color: map-get($color-map, gray);
    }
  }

}