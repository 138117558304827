.alert {
  $alert-root: &;

  text-align: center;
  position: relative;
  border: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: map-get($color-map, primary);

  &--fixy {
    position: fixed;
    z-index: 999;
    width: 100%;
  }

  // variante rossa
  &--danger {
    background-color: map-get($color-map, error);;
    color: map-get($color-map, white);;
  }

  &--browserupgrade { // todo
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    color: white;
    background: black;
    z-index: 9;
  }

}
