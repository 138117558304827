.box {
  border-radius: 4px;
  padding: 1.5rem;
  margin: 1.5rem 0;

  // box nested
  & {
    margin: 0;
    padding: 2rem;
    border-bottom: 1px solid map-deep-get($color-map, gallery);
  }
  
  @include respond-to("s-992") {
    &:first-child {
      margin-top: 0;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  }

  &--squared {
    border: 1px solid map-deep-get($color-map, gallery);
  }

  &--simple {
    background: map-deep-get($color-map, white);
  }


  &--outlined {
    border: 1px solid map-deep-get($color-map, tertiary);
    background: map-deep-get($color-map, white);
  }

  @at-root .listing__sidebar & {
    flex: 1;
  }
  
}