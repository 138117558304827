// Gestione dei mediaqueries
@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  @if $value != null {
    // If the key exists in the map Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
  
  @else {
    // If the key doesn't exist in the map
    @error "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


// BEM context
// il primo argomento fa riferimento alla root dei vari componenti (evitiamo il global)
// il secondo argmento è opzionale e serve per concatenare eventuali modificatori o elementi di quello principale
@mixin when-inside($selector, $child-selector: '') {
  $value: map-get($rootarea, $selector);
  @if $value != null {
    #{$value + $child-selector} & {
      @content;
    }
  }
  @else {
    // If the key doesn't exist in the map
    @error "Unfortunately, no value could be retrieved from `#{$selector}`. "
        + "Please make sure it is defined in `$rootarea` map.";
  }
}


// clear
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Vertical align
@mixin vertical-align-translate ($position:relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}
@mixin vertical-align-margin ($height) {
  position: absolute;
  top: 50%;
  margin-top: -($height/2);
}

// ROW
// --------------------
@mixin grid-row($nested: false) {

  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;

  @if $nested {
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
    width: calc(100% + #{$gutter});
  } @else {
    width: 100%;
  }
}

// COLUMN
// --------------------
@mixin grid-column($width: null, $collapse: false){
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @if $collapse == true {
    padding-left: 0;
    padding-right: 0;
  } @else {
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
  }


  @if type-of($width) != map {
    @if $width == 0 {
      width: auto;
      flex: 0 0 auto;
    } @else {
      @if $width == auto {
        flex: 1 1 0;
      } @else {
        width: calc(#{$column} * #{$width});
        flex: 0 0 auto;
      }
    }
  } @else {
    @each $bpoint, $value in $width {
      @include respond-to($bpoint) {
        @if $value == 0 {
          width: auto;
          flex: 0 0 auto;
        } @else {
          @if $value == auto {
            flex: 1 1 0;
          } @else {
            width: calc(#{$column} * #{$value});
            flex: 0 0 auto;
          }
        }
      }
    }
  }
}