.rating {
  $root: &;
  $max-level: 10;
  display: flex;

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__item {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: map-get($color-map, action );

    @for $i from 1 through $max-level {
      // prendo il valore del data-rate che mi arriva da back e lo uso per decolorare i pallini
      #{$root}[data-rate='#{$i}'] &:nth-child(#{$i}) ~ & {
        background: map-get($color-map, pale-grey);
      }
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  &__label {
    margin-left: 8px;
  }

}