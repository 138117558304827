.collection {
  $collection-root: &;

  // todo
  // [class^=col-] {
  // 	margin: 1.5rem 0
  // }

  // item
  &__item {
    padding: 3px;
    border: 1px solid map-get($color-map, gallery);
    position: relative;
  }

  // immagine
  &__figure {
    position: relative;
    overflow: hidden;
    background: map-get($color-map, gallery);
    margin: 0;
  }

  // testi
  &__figcaption {
    position: absolute;
    bottom: 0;
    background: rgba(map-get($color-map, primary), .8);
    left: 0;
    right: 0;
    padding: 1rem;
    color: white;
    opacity: 0;
    transition: opacity 250ms;

    // All'hover del componente -> escono i dettagli
    #{$collection-root}__figure:hover & {
      opacity: 1;
    }

    &-title {
      @extend %size-base;
      margin: 0;
      text-transform: uppercase;
    }

    &-info {
      margin: 0;
    }
  }

  // addTocart
  &__callToAction {
    @extend %size-medium;
  }

  // media
  &__media {
    transition: transform 250ms;

    &:hover {
      transform: scale(1.05);
    }

  }

}