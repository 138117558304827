.popover {

  &__cta {
    display: flex;

    &Inner {
      position: relative;
      display: flex;
    }
  }

  &__template {
    display: none;
  }
  
  &__content {
    padding: .5rem;
    pointer-events: auto!important;
  }

  &__title {
    @extend %size-small;
    color: map-get($map: $color-map, $key: 'secondary');
  }
  
  &__subtitle {
    margin: 0;
    @extend %size-micro;
  }

  &__list {

    &:not(:first-child) {
      margin-top: 1rem;
    }
    
    list-style: none;

    &-item {
      @extend %size-small;
      margin: 0 0 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid map_get($color-map, gallery);
      display: flex;

      &:last-child {
        margin: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  
  &__actions {
    margin-top: 1rem;

    .button {
      @extend %size-small;
    }
  }

}
