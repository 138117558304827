.tab {
  $root: &;

  margin: 50px; // test

  // navigazione tab
  &__nav {
    list-style: none;
    display: flex;
    border-bottom: 1px solid map-get($map: $color-map, $key: gallery);

    &-item {
      margin: 0 0 -1px 1rem;
    }

    &-anchor {
      
      position: relative;
      display: block;
      padding: 1rem 1.5rem;
      border: 1px solid transparent;
      border-radius: 3px 3px 0 0;

      #{$root}__nav-item--active & {
        color: map-get($map: $color-map, $key: gray);
        cursor: default;
        background-color: map-get($map: $color-map, $key: white);
        border: 1px solid map-get($map: $color-map, $key: gallery);
        border-bottom-color: transparent;
      }
    }

  }

  &__panel {

    padding: 1.5rem;
    

    // Primo tab è attivo di default
    &:not(.tab__panel--active) {
      display: none;
    }
    
  }

  

}