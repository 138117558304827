.form {
  $form-root: &;

  @mixin label-active() {
    font-size: 10px;
    transform: translate(10px, -16px);
  }

  &-group,
  &-group-wrapper {
    margin-bottom: 15px;
    position: relative;

    &:last-child {
      margin-bottom: 0
    }
  }

  &-helper-text {
    font-size: 9px;
    position: absolute;
    right: 9px;
    top: 12px;
    color: map-get($color-map, error);
    text-transform: uppercase;

    span {
      margin-left: 10px
    }
  }

  &-control {
    @extend %size-small;
    display: block;
    width: 100%;
    padding: 24px 8px 8px 8px;
    background-image: none;
    background-color: map-get($color-map, white);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    color: map-get($color-map, primary);
    border: 1px solid map-get($map: $color-map, $key: pale-grey);
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;

    &::placeholder {
      color: transparent!important;
    }
    
    #{$form-root}-group.has-error & {
      border-color: map-get($color-map, error);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    // Variante inline
    &--inline {
      display: inline-block;
      width: auto;
    }

    // variante select
    &-select {
      padding-right: 25px;
      background: #fff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'><path d='M13.418 7.859c0.271-0.268 0.709-0.268 0.978 0s0.272 0.701 0 0.969l-3.908 3.83c-0.27 0.268-0.707 0.268-0.979 0l-3.908-3.83c-0.27-0.267-0.27-0.701 0-0.969s0.709-0.268 0.978 0l3.421 3.141 3.418-3.141z'></path></svg>") no-repeat scroll calc(100% - 5px) center;
    }

    // Variante textarea
    &-area {
      height: 100px;
      padding: 8px;
    }

  }

  &-label {
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    max-width: 100%;
    vertical-align: text-top;
    color: rgba(map-get($map: $color-map, $key: merlin), .9);
    transition: all 200ms;

    #{$form-root}-group.has-error & {
      color: map-get($color-map, error);
    }

    &--material {
      position: absolute;
      transform: translate(10px, -50%);
      top: 50%;

      #{$form-root}-group.has-error & {
        @include label-active();
      }

      #{$form-root}-group--is-active & {
        @include label-active();
      }
  
      #{$form-root}-group--is-filled & {
        @include label-active();
      }
    }

    &--block {
      display: block;
    }

  }

  &-hint-popover {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 2.5rem;
  }

}