.media {

  // Formato responsive
  &--responsive {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  // Variante img in popover
  &--popover {
    max-width: 40px;
    margin-right: 1rem;
  }

  // Variante decorazione
  &--deco {
    padding: 2px;
    border: 1px solid map_get($color-map, gray);
  }

  //FROM https://bennettfeely.com/clippy/
  &--comics {
    -webkit-clip-path: polygon(24% 10%, 20% 0, 41% 6%, 100% 0, 100% 94%, 7% 100%, 0 24%);
    clip-path: polygon(24% 10%, 20% 0, 41% 6%, 100% 0, 100% 94%, 7% 100%, 0 24%);
  }

  &--knam {
    -webkit-clip-path: polygon(0 14%, 0 83%, 19% 83%, 19% 64%, 40% 51%, 74% 83%, 100% 83%, 53% 42%, 100% 12%, 77% 12%, 19% 49%, 20% 14%);
    clip-path: polygon(0 14%, 0 83%, 19% 83%, 19% 64%, 40% 51%, 74% 83%, 100% 83%, 53% 42%, 100% 12%, 77% 12%, 19% 49%, 20% 14%);
  }
  
  &--circle {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  }

  &--close {
    -webkit-clip-path: polygon(20% 0%, 0% 20%, 20% 50%, 0% 80%, 20% 100%, 50% 80%, 80% 100%, 100% 80%, 80% 50%, 100% 20%, 78% 0, 50% 20%);
    clip-path: polygon(20% 0%, 0% 20%, 20% 50%, 0% 80%, 20% 100%, 50% 80%, 80% 100%, 100% 80%, 80% 50%, 100% 20%, 78% 0, 50% 20%);    
  }

  &--hexagon {
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);    
  }

  &--left_left {
    -webkit-clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);    
  }

  &--left_point {
    -webkit-clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
    clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
  } 
  
  &--pentagon {
    -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);    
  }

  &--rabbet {
    -webkit-clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
    clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
  }

  &--rhombus {
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }

  &--right_point {
    -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  }

  &--right-right {
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);    
  }
  
  &--star4 {
    -webkit-clip-path: polygon(0% 15%, 100% 100%, 15% 0%, 85% 0%, 0 100%, 100% 15%, 100% 85%, 0 0, 85% 100%, 15% 100%, 100% 0, 0% 85%);
    clip-path: polygon(0% 15%, 100% 100%, 15% 0%, 85% 0%, 0 100%, 100% 15%, 100% 85%, 0 0, 85% 100%, 15% 100%, 100% 0, 0% 85%);
  } 

  &--triangle {
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  
  &--onLeft {
    max-width: 100%;
    display: block;
    margin:0 0 2rem;

    @include respond-to('s-768') {
      max-width: 30%;
      float: left;
      margin:0 1.5rem 0 0;
    }
  }

  &--onRight {
    max-width: 100%;
    display: block;
    margin:0 0 2rem;

    @include respond-to('s-768') {
      max-width: 30%;
      float: right;
      margin:0 0 0 1.5rem;
    }
  }

  &--album {
    position: relative;
    max-height: 400px;
    margin: 0 1rem 0 0;
    overflow: hidden;

    @include respond-to('s-768') {
      float: left;
      width: 50%;
    }

    a {
      display: block;
      position: relative;
      // top: 50%;
      // transform: translateY(-50%);
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 100px 100px 0 0;
      border-color: map-get($map: $color-map, $key: secondary) transparent transparent transparent;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    &:before {
      content: 'Open gallery';
      color: map-get($map: $color-map, $key: white);
      text-transform: uppercase;
      font-size: 15px;
      position: absolute;
      z-index: 1;
      transform: rotate(-45deg);
      top: 20px;
      left: -16px;
      width: 100px;
      text-align: center;
      line-height: 15px;
    }

  }

}