// Allignment
.align {
  &-left {text-align: left!important}
  &-center {text-align: center!important}
  &-right {text-align: right!important}
}

// Display
.display {
  &-block {display: block!important}
  &-none {display: none!important}
  &-inlineblock {display: inline-block!important}
}

.hidable__dk {
  @include respond-to("s-768") {
    display: none!important
  }
}

.hidable__mb {
  display: none!important;

  @include respond-to("s-768") {
    display: flex!important
  }
}

// Text style
.transform {
  &-uppercase { text-transform: uppercase!important}
  &-bold { }
  &-italic { font-style: italic!important}
  &-through {text-decoration: line-through!important}
}

// rotate
.rotate {
  &-180 {
    transform: rotate(180deg)
  }
}

.clearfix,
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.u-border-top {border-top: 1px solid map-get($color-map, secondary);}

.u-padding-vertical-small   {padding: 1.5rem 0 !important;}
.u-padding-vertical-medium  {padding: 3rem 0 !important;}
.u-padding-vertical-big     {padding: 8rem 0 !important;} 
.u-padding-top-none         {padding-top: 0!important;}

.u-padding-bottom-large {padding-bottom: 6rem !important;}

.u-margin-none  {margin: 0!important;}
.u-padding-none {padding: 0!important;}
.u-margin-small {margin-bottom: 1.5rem !important;margin-top: 1.5rem !important;}

.u-margin-bottom-none   {margin-bottom: 0!important;}
.u-margin-bottom-micro  {margin-bottom: 0.5rem !important;}
.u-margin-bottom-small  {margin-bottom: 1.5rem !important;}
.u-margin-bottom-medium {margin-bottom: 3rem !important;}
.u-margin-bottom-big    {margin-bottom: 8rem !important;}

.u-margin-top-none      {margin-top: 0!important;}
.u-margin-top-small     {margin-top: 1.5rem !important;}
.u-margin-top-medium    {margin-top: 3rem !important;}
.u-margin-top-big       {margin-top: 8rem !important;}
.u-margin-top-huge      {margin-top: 10rem !important;}

.u-margin-right-small   {margin-right: 1.5rem !important;}