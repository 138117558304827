.breadcrumb {
  padding: 0.5rem 1.2rem;
  background-color: map-get($color-map, white);
  border-radius: 3px;

  &__item {
    display: inline-block;
    text-transform: uppercase;

    a:link,
    a:visited {
      text-decoration: underline;
    }

    + li:before {
      content: "/\00a0";
      padding: 0 0.5rem;
    }

  }
}

