.listing {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;

  @include respond-to('s-768') {
    flex-direction: row;
    align-items: stretch;
    overflow: visible;
  }

  h2 {display: none}

  &__filterAction {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;

    svg {margin-right: 5px;}

    @include respond-to('s-768') {
      display: none;
    }
  }

  &__sidebar{
    background: map-get($color-map, white);
    position: absolute;
    right: -100%;
    top: 24px;
    z-index: 9;
    transition: right 300ms;

    &--show {
      right: -3px;
    }

    @include respond-to('s-768') {
      position: static;
      flex: 0 0 25%;
      margin-right: 30px;
      display: flex; // cosi il box all'interno è alto 100%
    }
  }

  &__products {
    flex: 1;
    transition: filter 300ms;
    
    &--blurred {
      filter: blur(2px);

      @include respond-to('s-768') {
        filter: none;
      }

      &:after {
        content: '';
        z-index: 1;
        position: fixed;
        top:65px;
        bottom:0;
        right:0;
        left: 0;

        @include respond-to('s-768') {
          display: none;
        }
      }
    }
  }


}