// Colors Map
// Thanks to http://chir.ag/projects/name-that-color

$color-map: (
  // main colors
  primary:    #122C0A,
  secondary:  #737900,
  terziary:   #62AF6B,
  action:     #9FA299,

  // Standard
  white:    #ffffff,
  black:    #000000,
  error:    #ff005c,

  // Gray
  warm-grey:        #9b9b9b,
  pale-grey:        #f5f2f4,
  blue-grey:       #9aa1b6,
  alabaster-light:  #f9f9f9,
  alabaster:        #f7f7f7,
  mercury:          #e8e8e8,
  gray:             #868686,
  gallery:          #efefef,
  merlin:           #3D3D38,
  dark-gray:        #0e0e0e,
  lower-grey:       #eeeeee,
  brown-grey:       #979797,

  // Others
  carnation:  #F95C69,
  buttercup:  #efac1a,
  apple:      #469b3f,
  paypal:     #003087,
  facebook:   #3b5998,
  twitter:    #55acee,
  pinterest:  #cc2127
);