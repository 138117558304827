$gutter: 1rem;
$basis: 12;
$column: (100% / $basis);

// breakpoints map
$breakpoints: (
  's-320': 320px,
  's-520': 520px,
  's-768':  768px,
  's-992':  992px,
  's-1200':  1200px,
  's-1500': 1500px
);

// BEM Mappa macroaree
$rootarea: (
  'page': '.page',
  'header': '.header',
  'accordion': '.accordion',
  'collection': '.collection',
  'footer': '.footer',
  'overlay': '.overlay',
  'button': '.button'
);