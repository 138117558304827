.angucomplete-holder {
  position: relative;
}

.angucomplete-dropdown {
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  min-width: 250px;
  width: 100%;
  padding: 6px;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  margin-top: 0px;
  background-color: #ffffff;
}

.angucomplete-searching {
  color: #acacac;
  font-size: 14px;
}

.angucomplete-description {
  font-size: 11px;
}

.angucomplete-row {
  padding: 5px;
  color: #000000;
  margin-bottom: 4px;
  clear: both;
}

.angucomplete-selected-row {
  background-color: lightblue;
  color: #ffffff;
}

.angucomplete-image-holder {
  padding-top: 2px;
  float: left;
  margin-right: 10px;
  margin-left: 5px;
}

.angucomplete-image {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-color: #ececec;
  border-style: solid;
  border-width: 1px;
}

.angucomplete-image-default {
  /* Add your own default image here
   background-image: url('/assets/default.png');
  */
  background-position: center;
  background-size: contain;
  height: 34px;
  width: 34px;
}




.angucomplete {

  &-dropdown {
    min-width: 250px;
    width: 100%;  
    padding: 0px;
    margin-top: 0;
    max-height: 200px;
    overflow: auto;
  }

  &-row {
    padding: 4px;
    margin-bottom: 0px;
    border-bottom: 1px solid map-get($color-map, gallery);
    @extend %size-small;
  }

  &-selected-row {
    background-color: map-get($color-map, secondary);
  }

  &-title {}

}