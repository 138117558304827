@charset "UTF-8";
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

html,
body {
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #122C0A;
  min-height: 100vh;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

hr {
  border-bottom: 0;
  border-color: #737900;
}

.footer hr {
  border-color: #62AF6B;
}

a:link,
a:visited {
  color: #122C0A;
  text-decoration: none;
  transition: all 250ms;
}

a:hover,
a:active {
  color: black;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  appearance: none;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  outline: 0;
}

label,
legend {
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

.size-huge,
.style-alpha,
.style-delta {
  font-size: 2.4rem;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .size-huge,
  .style-alpha,
  .style-delta {
    font-size: 4rem;
  }
}

.size-big,
.style-foxtrot,
.slick-claim .tag,
.tingle-modal__close {
  font-size: 2rem;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .size-big,
  .style-foxtrot,
  .slick-claim .tag,
  .tingle-modal__close {
    font-size: 3rem;
  }
}

.size-large,
.style-charlie,
blockquote {
  font-size: 2.6rem;
  line-height: 1;
}

.size-medium-large,
.style-echo,
.card--delta .card__title,
.card--foxtrot .card__title,
.style-india,
.style-juliett {
  font-size: 2rem;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .size-medium-large,
  .style-echo,
  .card--delta .card__title,
  .card--foxtrot .card__title,
  .style-india,
  .style-juliett {
    font-size: 2.4rem;
  }
}

.size-medium,
.style-bravo,
.style-golf,
.collection__callToAction,
.logo__text,
.card--golf .card__title,
.masonry__title {
  font-size: 2rem;
  line-height: 2.6rem;
}

.size-normal,
.bookPreview__title,
.bookPreview__year,
.date__month,
.card--alpha .card__title {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.size-base,
body,
.accordion__title,
.collection__figcaption-title {
  font-size: 1.6rem;
  line-height: 2.6rem;
}

.size-small,
.bookPreview__author,
.style-hotel,
.header__banner,
.form-control,
.angucomplete-row,
.accordion__infoText,
.popover__title,
.popover__list-item,
.popover__actions .button,
.card__description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.size-micro,
.footer__title,
.footer__list,
.footer__table,
.footer__bottom,
.popover__subtitle {
  font-size: 1.2rem;
}

.color-primary {
  color: #122C0A;
}

.bg-primary {
  background-color: #122C0A;
}

.color-secondary {
  color: #737900;
}

.bg-secondary {
  background-color: #737900;
}

.color-terziary {
  color: #62AF6B;
}

.bg-terziary {
  background-color: #62AF6B;
}

.color-action {
  color: #9FA299;
}

.bg-action {
  background-color: #9FA299;
}

.color-white {
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.color-black {
  color: #000000;
}

.bg-black {
  background-color: #000000;
}

.color-error {
  color: #ff005c;
}

.bg-error {
  background-color: #ff005c;
}

.color-warm-grey {
  color: #9b9b9b;
}

.bg-warm-grey {
  background-color: #9b9b9b;
}

.color-pale-grey {
  color: #f5f2f4;
}

.bg-pale-grey {
  background-color: #f5f2f4;
}

.color-blue-grey {
  color: #9aa1b6;
}

.bg-blue-grey {
  background-color: #9aa1b6;
}

.color-alabaster-light {
  color: #f9f9f9;
}

.bg-alabaster-light {
  background-color: #f9f9f9;
}

.color-alabaster {
  color: #f7f7f7;
}

.bg-alabaster {
  background-color: #f7f7f7;
}

.color-mercury {
  color: #e8e8e8;
}

.bg-mercury {
  background-color: #e8e8e8;
}

.color-gray {
  color: #868686;
}

.bg-gray {
  background-color: #868686;
}

.color-gallery {
  color: #efefef;
}

.bg-gallery {
  background-color: #efefef;
}

.color-merlin {
  color: #3D3D38;
}

.bg-merlin {
  background-color: #3D3D38;
}

.color-dark-gray {
  color: #0e0e0e;
}

.bg-dark-gray {
  background-color: #0e0e0e;
}

.color-lower-grey {
  color: #eeeeee;
}

.bg-lower-grey {
  background-color: #eeeeee;
}

.color-brown-grey {
  color: #979797;
}

.bg-brown-grey {
  background-color: #979797;
}

.color-carnation {
  color: #F95C69;
}

.bg-carnation {
  background-color: #F95C69;
}

.color-buttercup {
  color: #efac1a;
}

.bg-buttercup {
  background-color: #efac1a;
}

.color-apple {
  color: #469b3f;
}

.bg-apple {
  background-color: #469b3f;
}

.color-paypal {
  color: #003087;
}

.bg-paypal {
  background-color: #003087;
}

.color-facebook {
  color: #3b5998;
}

.bg-facebook {
  background-color: #3b5998;
}

.color-twitter {
  color: #55acee;
}

.bg-twitter {
  background-color: #55acee;
}

.color-pinterest {
  color: #cc2127;
}

.bg-pinterest {
  background-color: #cc2127;
}

.livedit {
  position: relative;
}

.debug-off pre {
  display: none;
}

@media screen and (max-width: 992px) {
  .standby {
    overflow: hidden;
  }
}

.bookPreview {
  display: flex;
}

.bookPreview__thumb {
  flex-basis: 50%;
  margin-right: .8rem;
}

.bookPreview__thumbSource {
  max-width: 100%;
  display: block;
}

.bookPreview__title {
  font-family: "Raleway";
  font-weight: 700;
  font-style: italic;
}

.map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map__iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border: 0;
}

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */
.row [class*="col-"] {
  margin-bottom: 1.5rem;
}

body {
  font-family: "Raleway";
}

.style-alpha {
  font-family: "Raleway";
  font-weight: 700;
  text-transform: uppercase;
}

.style-bravo {
  font-family: "Raleway";
  color: #737900;
}

.style-charlie {
  font-family: "Raleway";
  font-weight: 700;
  text-transform: uppercase;
}

.style-charlie::after {
  content: '';
  display: block;
  max-width: 80px;
  height: 6px;
  background-color: #62AF6B;
  margin-top: 10px;
}

.style-delta {
  font-family: "Raleway";
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  padding-left: 20px;
}

.style-delta::after {
  content: "";
  display: block;
  width: 10px;
  height: calc(100% - 11px);
  position: absolute;
  left: 0;
  top: 3px;
  background-color: #62AF6B;
}

.style-echo,
.card--delta .card__title,
.card--foxtrot .card__title {
  font-family: "Raleway";
}

.style-foxtrot {
  font-family: "Raleway";
  font-weight: 700;
}

.style-golf {
  font-family: "Raleway";
  font-weight: 300;
}

.style-hotel {
  font-family: "Raleway";
  font-weight: 700;
}

.style-india {
  font-family: "Raleway";
  font-weight: 700;
  text-transform: uppercase;
}

.style-juliett {
  font-family: "Raleway";
  font-weight: 300;
}

p {
  margin: 1.5rem 0;
}

p:last-child {
  margin: 0;
}

ul li {
  margin: 1.5rem 0;
}

blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}

blockquote:before, blockquote:after {
  font-size: 250%;
  line-height: 0.1em;
  vertical-align: -0.4em;
}

blockquote:before {
  content: open-quote;
  margin-right: 2.5rem;
}

blockquote:after {
  content: close-quote;
  margin-left: 0;
}

.date {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
}

.date--boxed {
  width: 81px;
  height: 81px;
  box-shadow: 0 1px 5px 0 rgba(124, 132, 149, 0.5);
  background-color: #ffffff;
  justify-content: center;
}

.date__month {
  font-family: "Raleway";
  font-weight: 300;
  letter-spacing: 2.57px;
  line-height: 0.9;
  margin-bottom: 10px;
}

.date__day {
  font-size: 3.6rem;
  font-family: "Raleway";
  letter-spacing: 3px;
  line-height: 0.8;
}

.text--author strong:after {
  content: ' - ';
}

.text--price {
  display: flex;
  flex-direction: column;
}

.text--price > * {
  line-height: 1.2;
}

.header {
  width: 100%;
  padding: 0;
  transition: background 250ms;
}

.header--alpha {
  background: #ffffff;
}

.header--bravo, .header--charlie {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.header--delta {
  background: #f5f2f4;
}

.header--bravo .header__main {
  background: linear-gradient(to bottom, #38102f, rgba(56, 16, 47, 0));
  min-height: 140px;
}

@media (min-width: 768px) {
  .header--bravo .header__main {
    background: linear-gradient(to bottom, #38102f, rgba(56, 16, 47, 0.35) 52%, rgba(248, 248, 248, 0));
    min-height: 240px;
  }
}

.header--charlie .header__main {
  background: linear-gradient(to bottom, #ffc0cb, rgba(255, 192, 203, 0));
  min-height: 140px;
}

@media (min-width: 768px) {
  .header--charlie .header__main {
    background: linear-gradient(to bottom, #ffc0cb, rgba(255, 192, 203, 0.35) 52%, rgba(255, 192, 203, 0));
    min-height: 240px;
  }
}

.header__inner {
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
  position: relative;
}

@media (min-width: 768px) {
  .header__inner {
    padding: 2.5rem 0;
    flex-direction: row;
    align-items: center;
  }
}

.header__banner {
  padding: .5rem 0;
  background: #f7f7f7;
  display: none;
}

@media (min-width: 768px) {
  .header__banner {
    display: block;
  }
}

.header__callme {
  display: flex;
  align-items: center;
}

.header__callme > svg {
  margin-right: 5px;
  width: 14px;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  flex-shrink: 0;
  width: 170px;
}

.header__topbar .header__logo {
  width: 120px;
  margin: 5px 0;
}

.header__topbar {
  position: fixed;
  padding: 0;
  left: 0;
  right: 0;
  top: -50px;
  transition: top 400ms, box-shadow 200ms 200ms;
  background: #ffffff;
  z-index: 9999;
}

.header__topbar a:link,
.header__topbar a:visited {
  color: #122C0A;
}

.header__topbar--visible {
  top: 0;
  box-shadow: 0 0.8rem 2rem -0.9rem rgba(0, 0, 0, 0.3);
}

.header__topbarInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__navigation {
  list-style: none;
  display: flex;
  width: 100%;
  align-items: center;
  order: 3;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .header__navigation {
    width: auto;
    justify-content: flex-start;
  }
}

.header__navigationItem {
  text-transform: uppercase;
  margin: 0;
}

.header__navigationItem:not(:last-child) {
  margin-right: 1rem;
}

.header__navigationItem .navigation__anchor {
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  color: white;
}

.header--alpha .header__navigationItem .navigation__anchor {
  color: #62AF6B;
}

.header--delta .header__navigationItem .navigation__anchor {
  color: #122C0A;
}

.navigation {
  padding: 0 1.6rem;
  max-width: 1200px;
}

@media (min-width: 992px) {
  .navigation {
    margin-top: 0;
    order: 2;
  }
}

.navigation__wrap {
  list-style: none;
  position: fixed;
  top: 0;
  left: -100%;
  width: calc(100% - 60px);
  height: 100vh;
  z-index: 999;
  box-shadow: 0 0px 8px 1px rgba(0, 0, 0, 0.5);
  transition: opacity 200ms, left 200ms;
  transform: none;
}

.navigation__wrap--firstLevel {
  background: linear-gradient(to bottom, #737900 0%, #434600 100%);
  overflow: auto;
}

.navigation__wrap--firstLevel.navigation__wrap--visible {
  left: 0;
}

@media (min-width: 992px) {
  .navigation__wrap--firstLevel {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .navigation__wrap {
    position: static;
    margin: 0;
    opacity: 1;
    height: auto;
    box-shadow: none;
    width: 100%;
    background: none;
    overflow: visible;
  }
}

.navigation__wrap--secondLevel, .navigation__wrap--thirdLevel {
  width: 100%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: static;
  box-shadow: none;
}

.navigation__wrap--secondLevel.navigation__wrap--visible, .navigation__wrap--thirdLevel.navigation__wrap--visible {
  opacity: 1;
  height: auto;
  overflow: visible;
}

@media (min-width: 992px) {
  .navigation__wrap--secondLevel, .navigation__wrap--thirdLevel {
    font-size: 90%;
    top: 100%;
    position: absolute;
    z-index: 999;
    border-radius: 3px;
    text-align: left;
    min-width: 18rem;
    width: auto;
  }
}

.navigation__wrap--secondLevel {
  background: #737900;
}

@media (min-width: 992px) {
  .navigation__wrap--secondLevel {
    left: 0;
  }
}

.navigation__wrap--thirdLevel {
  background: #737900;
}

@media (min-width: 992px) {
  .navigation__wrap--thirdLevel {
    left: auto;
    right: 100%;
    top: 0;
  }
}

.navigation__item {
  border-top: 1px solid #4c5000;
  background: transparent;
  position: relative;
  margin: 0;
  white-space: nowrap;
}

.navigation__wrap--firstLevel > .navigation__item {
  border: 0;
}

@media (min-width: 992px) {
  .navigation__wrap--firstLevel > .navigation__item {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-right: 1.6rem;
  }
}

.navigation__item:first-child {
  border-top: 0;
}

@media (min-width: 992px) {
  .navigation__wrap--firstLevel > .navigation__item:first-child {
    border: 0;
  }
}

.navigation__anchor {
  display: block;
  text-align: left;
  padding: 2rem;
}

.navigation__anchor svg {
  width: 21px;
  height: 21px;
}

.header--highlight .navigation__anchor:link, .header--highlight .navigation__anchor:visited {
  color: #122C0A;
}

.header--plain .navigation__anchor:link, .header--plain .navigation__anchor:visited {
  color: #122C0A;
}

@media (min-width: 992px) {
  .navigation__anchor {
    padding: 1rem;
  }
}

.navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
  color: white;
}

@media (min-width: 992px) {
  .navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
    display: flex;
    align-items: center;
    padding: .5rem;
  }
  .header--alpha .navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
    color: #122C0A;
  }
  .header--charlie .navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
    color: #122C0A;
  }
  .header--delta .navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
    color: #122C0A;
  }
  .header__topbar .navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
    color: #122C0A;
  }
}

@media (min-width: 992px) {
  .navigation__wrap--firstLevel > .navigation__item > .navigation__anchor {
    padding: 0;
  }
}

.navigation__wrap--secondLevel > .navigation__item > .navigation__anchor {
  color: white;
}

.navigation__wrap--secondLevel > .navigation__item > .navigation__anchor:hover, .navigation__wrap--secondLevel > .navigation__item > .navigation__anchor:active {
  background: #5b6000;
}

.navigation__wrap--thirdLevel > .navigation__item > .navigation__anchor {
  color: white;
}

.navigation__wrap--thirdLevel > .navigation__item > .navigation__anchor:hover, .navigation__wrap--thirdLevel > .navigation__item > .navigation__anchor:active {
  background: #515500;
}

.navigation__anchor--arrow {
  position: relative;
  padding-right: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 992px) {
  .navigation .js-openSubMenu:hover + .navigation__wrap,
  .navigation .js-openSubMenu + .navigation__wrap:hover {
    opacity: 1;
    height: auto;
    overflow: visible;
  }
}

.navigation__close {
  position: fixed;
  z-index: 9999;
  color: white;
  right: 66px;
  transform: rotate(45deg);
  width: 40px !important;
  height: 40px !important;
  top: 10px;
  display: block;
}

@media (min-width: 992px) {
  .navigation__close {
    display: none;
  }
}

.navigation__ham {
  position: absolute;
  display: block;
  right: 0;
  line-height: normal;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.navigation__ham-bar {
  display: block;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
  width: 2.2rem;
}

.navigation__ham-bar:not(:first-child) {
  margin: 0.4rem auto 0;
}

.navigation__ham-bar:nth-child(2) {
  width: 1.7rem;
  margin-left: 0;
}

.header--alpha .navigation__ham-bar {
  background-color: #62AF6B;
}

@media (min-width: 992px) {
  .navigation__ham {
    display: none;
  }
}

.form-group, .form-group-wrapper {
  margin-bottom: 15px;
  position: relative;
}

.form-group:last-child, .form-group-wrapper:last-child {
  margin-bottom: 0;
}

.form-helper-text {
  font-size: 9px;
  position: absolute;
  right: 9px;
  top: 12px;
  color: #ff005c;
  text-transform: uppercase;
}

.form-helper-text span {
  margin-left: 10px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 24px 8px 8px 8px;
  background-image: none;
  background-color: #ffffff;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  color: #122C0A;
  border: 1px solid #f5f2f4;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
}

.form-control::placeholder {
  color: transparent !important;
}

.form-group.has-error .form-control {
  border-color: #ff005c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control--inline {
  display: inline-block;
  width: auto;
}

.form-control-select {
  padding-right: 25px;
  background: #fff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'><path d='M13.418 7.859c0.271-0.268 0.709-0.268 0.978 0s0.272 0.701 0 0.969l-3.908 3.83c-0.27 0.268-0.707 0.268-0.979 0l-3.908-3.83c-0.27-0.267-0.27-0.701 0-0.969s0.709-0.268 0.978 0l3.421 3.141 3.418-3.141z'></path></svg>") no-repeat scroll calc(100% - 5px) center;
}

.form-control-area {
  height: 100px;
  padding: 8px;
}

.form-label {
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  max-width: 100%;
  vertical-align: text-top;
  color: rgba(61, 61, 56, 0.9);
  transition: all 200ms;
}

.form-group.has-error .form-label {
  color: #ff005c;
}

.form-label--material {
  position: absolute;
  transform: translate(10px, -50%);
  top: 50%;
}

.form-group.has-error .form-label--material {
  font-size: 10px;
  transform: translate(10px, -16px);
}

.form-group--is-active .form-label--material {
  font-size: 10px;
  transform: translate(10px, -16px);
}

.form-group--is-filled .form-label--material {
  font-size: 10px;
  transform: translate(10px, -16px);
}

.form-label--block {
  display: block;
}

.form-hint-popover {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 2.5rem;
}

.footer__adv {
  padding: 3rem 0;
}

.footer__advItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer__advItem > svg {
  margin-bottom: 5rem;
}

.footer__top {
  padding: 1.5rem 0 0;
  background: #737900;
  color: #122C0A;
  position: relative;
}

.footer__top a:link,
.footer__top a:visited {
  color: #122C0A;
}

.footer__title {
  margin-bottom: 0.8rem;
  line-height: 1;
}

.footer__list {
  list-style: none;
  line-height: 1.4;
  margin-bottom: 15px;
}

.footer__list > li {
  margin: 0 0 0.2rem 0;
}

.footer__list--spaced {
  display: flex;
  flex-wrap: wrap;
}

.footer__list--spaced li {
  margin: 0 0 1.8rem;
  flex-basis: 50%;
}

.footer__table {
  width: 100%;
}

.footer__table td {
  line-height: 1.7;
}

.footer__bottom {
  padding: 1.5rem 0;
  background: #122c0a;
  color: #ffffff;
}

.footer__bottom a:link,
.footer__bottom a:visited {
  color: #ffffff;
}

.listing {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
}

@media (min-width: 768px) {
  .listing {
    flex-direction: row;
    align-items: stretch;
    overflow: visible;
  }
}

.listing h2 {
  display: none;
}

.listing__filterAction {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.listing__filterAction svg {
  margin-right: 5px;
}

@media (min-width: 768px) {
  .listing__filterAction {
    display: none;
  }
}

.listing__sidebar {
  background: #ffffff;
  position: absolute;
  right: -100%;
  top: 24px;
  z-index: 9;
  transition: right 300ms;
}

.listing__sidebar--show {
  right: -3px;
}

@media (min-width: 768px) {
  .listing__sidebar {
    position: static;
    flex: 0 0 25%;
    margin-right: 30px;
    display: flex;
  }
}

.listing__products {
  flex: 1;
  transition: filter 300ms;
}

.listing__products--blurred {
  filter: blur(2px);
}

@media (min-width: 768px) {
  .listing__products--blurred {
    filter: none;
  }
}

.listing__products--blurred:after {
  content: '';
  z-index: 1;
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (min-width: 768px) {
  .listing__products--blurred:after {
    display: none;
  }
}

.angucomplete-holder {
  position: relative;
}

.angucomplete-dropdown {
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  min-width: 250px;
  width: 100%;
  padding: 6px;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  margin-top: 0px;
  background-color: #ffffff;
}

.angucomplete-searching {
  color: #acacac;
  font-size: 14px;
}

.angucomplete-description {
  font-size: 11px;
}

.angucomplete-row {
  padding: 5px;
  color: #000000;
  margin-bottom: 4px;
  clear: both;
}

.angucomplete-selected-row {
  background-color: lightblue;
  color: #ffffff;
}

.angucomplete-image-holder {
  padding-top: 2px;
  float: left;
  margin-right: 10px;
  margin-left: 5px;
}

.angucomplete-image {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-color: #ececec;
  border-style: solid;
  border-width: 1px;
}

.angucomplete-image-default {
  /* Add your own default image here
   background-image: url('/assets/default.png');
  */
  background-position: center;
  background-size: contain;
  height: 34px;
  width: 34px;
}

.angucomplete-dropdown {
  min-width: 250px;
  width: 100%;
  padding: 0px;
  margin-top: 0;
  max-height: 200px;
  overflow: auto;
}

.angucomplete-row {
  padding: 4px;
  margin-bottom: 0px;
  border-bottom: 1px solid #efefef;
}

.angucomplete-selected-row {
  background-color: #737900;
}

.tippy-tooltip {
  font-size: 1rem;
}

.trumbowyg-button-pane {
  z-index: 9;
}

.accordion__group {
  padding: 1rem 0;
}

.accordion--simple .accordion__group {
  border-bottom: 1px dotted #122C0A;
}

.accordion--simple .accordion__group:last-child {
  border: 0;
}

.accordion__heading {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.accordion--simple .accordion__heading {
  padding: 1.1rem 0;
}

.accordion--deco .accordion__heading {
  background: rgba(239, 239, 239, 0.8);
  margin-bottom: 1px;
  transition: background 250ms;
  padding: 1.1rem;
}

.accordion--deco .accordion__group--active .accordion__heading {
  background: rgba(214, 214, 214, 0.8);
}

.accordion__title {
  text-transform: uppercase;
  margin: 0 1.1rem 0 0;
}

.accordion__collapse-inner {
  display: none;
}

.accordion--simple .accordion__collapse-inner {
  padding: 0 0 1.1rem;
}

.accordion--deco .accordion__collapse-inner {
  background: rgba(239, 239, 239, 0.5);
  padding: 1.1rem 2rem;
}

.accordion__infoText {
  margin: 0 1.5rem 0 0;
}

.accordion__arrow {
  transition: transform 250ms;
}

.accordion__group--active .accordion__arrow {
  transform: rotate(180deg);
}

.slick-slider {
  position: relative;
  display: block;
  user-select: none;
  touch-action: pan-y;
}

.slick--fluid {
  height: 100vh;
}

.slick-claim {
  position: absolute;
  left: 50%;
  bottom: 10%;
  width: 90%;
  height: auto;
  border-left: 0px solid white;
  padding-left: 15px;
  transition: border-width 400ms 400ms;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .slick-claim {
    width: auto;
  }
}

.slick-active .slick-claim {
  border-width: 6px;
}

.slick-claim h2,
.slick-claim p,
.slick-claim button {
  opacity: 0;
  position: relative;
  left: -25px;
  transition: all 800ms 600ms;
}

.slick-active .slick-claim h2, .slick-active
.slick-claim p, .slick-active
.slick-claim button {
  left: 0;
  opacity: 1;
}

.slick-claim h2 {
  font-size: 18px;
  line-height: 1;
}

@media (min-width: 768px) {
  .slick-claim h2 {
    font-size: 30px;
  }
}

.slick-claim p {
  max-width: 570px;
}

.slick-claim .tag {
  margin-right: 7px;
  float: left;
  letter-spacing: -1px;
  padding: 0px 6px;
}

.slick-claim .tag + .txt {
  overflow: hidden;
  display: block;
}

.slick-track, .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick--fluid .slick-track, .slick--fluid .slick-list {
  height: 100%;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list :focus {
  outline: none;
}

.slick-list .dragging {
  cursor: hand;
}

.slick-slide {
  display: none;
  float: left;
  min-height: 1px;
}

.slick--fluid .slick-slide {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.slick--fluid .slick-slide div {
  height: inherit;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.slick--showcase .slick-slide {
  padding: 0 1.5rem 0 0;
  max-height: 50rem;
}

.slick--showcase .slick-slide img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.slick--showcase .slick-slide > div {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: calc(100% - 5px);
}

.slick--zoom .slick-slide {
  max-height: 40rem;
  margin-bottom: 1rem;
  background: white;
}

.slick--zoom .slick-slide img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  max-height: 40rem;
}

.slick--zoom-nav .slick-slide {
  margin: 0 .5rem;
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(57, 15, 47, 0.09);
  border: 1px solid #eee;
  position: relative;
  overflow: hidden;
  height: 150px;
}

.slick--zoom-nav .slick-slide img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.slick-slide:last-child {
  padding-right: 0;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  transition: left 400ms;
}

.slick--showcase .slick-track {
  display: flex;
}

.slick-move .slick-track {
  left: -5rem;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-track :before,
.slick-track :after {
  display: table;
  content: '';
}

.slick-track :after {
  clear: both;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  width: 71px;
  height: 71px;
  box-shadow: 0 0 13px 0 rgba(38, 38, 38, 0.2);
  background-color: #ffffff;
  margin-top: -35px;
  cursor: pointer;
  color: #122C0A;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-arrow > svg {
  width: 71px;
  height: 71px;
}

.touch .slick-arrow {
  display: none !important;
}

.slick-arrow-prev {
  left: -35px;
}

.slick-arrow-next {
  right: -20px;
}

.slick-dots {
  text-align: center;
}

.slick--fluid .slick-dots {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0;
  background: #f5f2f4;
  padding: 5px 20px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  transform: translateX(-50%);
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;
}

.slick--fluid .slick-dots li {
  border-radius: 10px;
  height: 8px;
  width: auto;
  min-width: 12px;
}

.slick-dots li.slick-active button {
  background: black;
}

.slick--fluid .slick-dots li.slick-active button {
  width: 24px;
  background: #62AF6B;
  border-radius: 10px;
  opacity: 1;
}

.slick-dots button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  outline: none;
  border-radius: 100%;
  background: #122C0A;
  border: 1px solid black;
}

.slick--fluid .slick-dots button {
  background: #62AF6B;
  opacity: .2;
  border-color: transparent;
  padding: 0;
  height: 8px;
  width: 8px;
}

.slick__toZoom {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 35px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px 0 0;
  background: rgba(255, 255, 255, 0.9);
  left: 50%;
  transform: translate(-50%);
}

@media (min-width: 768px) {
  .slick__toZoom {
    left: auto;
    right: 0;
  }
}

.slick__toZoom > svg {
  width: 30px;
  height: 30px;
  fill: #62AF6B;
}

.collection__item {
  padding: 3px;
  border: 1px solid #efefef;
  position: relative;
}

.collection__figure {
  position: relative;
  overflow: hidden;
  background: #efefef;
  margin: 0;
}

.collection__figcaption {
  position: absolute;
  bottom: 0;
  background: rgba(18, 44, 10, 0.8);
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
  opacity: 0;
  transition: opacity 250ms;
}

.collection__figure:hover .collection__figcaption {
  opacity: 1;
}

.collection__figcaption-title {
  margin: 0;
  text-transform: uppercase;
}

.collection__figcaption-info {
  margin: 0;
}

.collection__media {
  transition: transform 250ms;
}

.collection__media:hover {
  transform: scale(1.05);
}

.logo {
  margin: 0;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .header--vertical .logo {
    margin: 0 0 1.5rem;
  }
}

.logo__img {
  width: 100%;
  max-width: 20rem;
}

.logo__text {
  white-space: nowrap;
  color: #ffffff;
}

.header--highlight .logo__text {
  color: #122C0A;
}

.header--plain .logo__text {
  color: #122C0A;
}

.logo__text-sub {
  font-size: 90%;
  display: block;
  text-align: center;
  color: #868686;
}

.popover__cta {
  display: flex;
}

.popover__ctaInner {
  position: relative;
  display: flex;
}

.popover__template {
  display: none;
}

.popover__content {
  padding: .5rem;
  pointer-events: auto !important;
}

.popover__title {
  color: #737900;
}

.popover__subtitle {
  margin: 0;
}

.popover__list {
  list-style: none;
}

.popover__list:not(:first-child) {
  margin-top: 1rem;
}

.popover__list-item {
  margin: 0 0 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #efefef;
  display: flex;
}

.popover__list-item:last-child {
  margin: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.popover__actions {
  margin-top: 1rem;
}

.button {
  display: inline-block;
  zoom: 1;
  font-family: "Raleway";
  font-size: 1.8rem;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 0;
  transition: opacity 200ms;
  height: 50px;
  padding: 0 40px;
}

@media (min-width: 768px) {
  .button {
    margin-top: 0;
  }
}

.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.button-icontext {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button-interactive {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 999999;
  padding: 0;
}

.button-interactive > svg {
  width: 25px;
  height: 25px;
}

.button:focus, .button:hover {
  opacity: 0.8;
}

.button:active {
  border-color: #000000;
}

.button[disabled] {
  border: 1px solid transparent;
  background-image: none;
  filter: alpha(opacity=40);
  opacity: .3;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
}

.button-shadow {
  box-shadow: 0 7px 20px 0 rgba(18, 44, 10, 0.2);
}

.button-shadow-small {
  box-shadow: 0 1px 5px 0 rgba(154, 161, 182, 0.5);
}

.button-transparent {
  background: transparent;
  border: 0;
}

.button-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0;
  width: 50px;
  height: 50px;
}

.button-icon-square {
  border-radius: 2px;
}

.button-icon.button-small {
  width: 22px;
  height: 22px;
}

.button-icon.button-normal {
  width: 42px;
  height: 42px;
}

.button-icon.button-large {
  width: 57px;
  height: 57px;
}

.button-outline, .button-outline-light, .button-outline-light:link, .button-outline-light:visited, .button-outline-dark, .button-outline-dark:link, .button-outline-dark:visited {
  border: 1px solid;
}

.button-outline-light, .button-outline-light:link, .button-outline-light:visited {
  border-color: #ffffff;
  color: #ffffff;
}

.button-outline-dark, .button-outline-dark:link, .button-outline-dark:visited {
  border-color: #62AF6B;
  color: #62AF6B;
}

.button-primary, .button-secondary, .button-terziary, .button-success, .button-error, .button-warning, .button-paypal, .button-facebook, .button-twitter, .button-pinterest {
  color: #ffffff !important;
}

.button-primary {
  background: #122C0A;
}

.button-secondary {
  background: #737900;
}

.button-terziary {
  background: #62AF6B;
}

.button-success {
  background: #469b3f;
}

.button-error {
  background: #F95C69;
}

.button-warning {
  background: #efac1a;
}

.button-paypal {
  background: #003087;
}

.button-facebook {
  background: #3b5998;
}

.button-twitter {
  background: #55acee;
}

.button-pinterest {
  background: #cc2127;
}

.button-small {
  height: 22px;
  font-size: 1.2rem;
  padding: 0 2px;
}

.button-large {
  height: 57px;
  font-size: 2.2rem;
}

.button-block {
  width: 100%;
}

.alert {
  text-align: center;
  position: relative;
  border: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #122C0A;
}

.alert--fixy {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.alert--danger {
  background-color: #ff005c;
  color: #ffffff;
}

.alert--browserupgrade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  color: white;
  background: black;
  z-index: 9;
}

.media--responsive {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.media--popover {
  max-width: 40px;
  margin-right: 1rem;
}

.media--deco {
  padding: 2px;
  border: 1px solid #868686;
}

.media--comics {
  -webkit-clip-path: polygon(24% 10%, 20% 0, 41% 6%, 100% 0, 100% 94%, 7% 100%, 0 24%);
  clip-path: polygon(24% 10%, 20% 0, 41% 6%, 100% 0, 100% 94%, 7% 100%, 0 24%);
}

.media--knam {
  -webkit-clip-path: polygon(0 14%, 0 83%, 19% 83%, 19% 64%, 40% 51%, 74% 83%, 100% 83%, 53% 42%, 100% 12%, 77% 12%, 19% 49%, 20% 14%);
  clip-path: polygon(0 14%, 0 83%, 19% 83%, 19% 64%, 40% 51%, 74% 83%, 100% 83%, 53% 42%, 100% 12%, 77% 12%, 19% 49%, 20% 14%);
}

.media--circle {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
}

.media--close {
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 20% 50%, 0% 80%, 20% 100%, 50% 80%, 80% 100%, 100% 80%, 80% 50%, 100% 20%, 78% 0, 50% 20%);
  clip-path: polygon(20% 0%, 0% 20%, 20% 50%, 0% 80%, 20% 100%, 50% 80%, 80% 100%, 100% 80%, 80% 50%, 100% 20%, 78% 0, 50% 20%);
}

.media--hexagon {
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.media--left_left {
  -webkit-clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.media--left_point {
  -webkit-clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
  clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
}

.media--pentagon {
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.media--rabbet {
  -webkit-clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
  clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
}

.media--rhombus {
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.media--right_point {
  -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.media--right-right {
  -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.media--star4 {
  -webkit-clip-path: polygon(0% 15%, 100% 100%, 15% 0%, 85% 0%, 0 100%, 100% 15%, 100% 85%, 0 0, 85% 100%, 15% 100%, 100% 0, 0% 85%);
  clip-path: polygon(0% 15%, 100% 100%, 15% 0%, 85% 0%, 0 100%, 100% 15%, 100% 85%, 0 0, 85% 100%, 15% 100%, 100% 0, 0% 85%);
}

.media--triangle {
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.media--onLeft {
  max-width: 100%;
  display: block;
  margin: 0 0 2rem;
}

@media (min-width: 768px) {
  .media--onLeft {
    max-width: 30%;
    float: left;
    margin: 0 1.5rem 0 0;
  }
}

.media--onRight {
  max-width: 100%;
  display: block;
  margin: 0 0 2rem;
}

@media (min-width: 768px) {
  .media--onRight {
    max-width: 30%;
    float: right;
    margin: 0 0 0 1.5rem;
  }
}

.media--album {
  position: relative;
  max-height: 400px;
  margin: 0 1rem 0 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .media--album {
    float: left;
    width: 50%;
  }
}

.media--album a {
  display: block;
  position: relative;
}

.media--album:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 100px 0 0;
  border-color: #737900 transparent transparent transparent;
  position: absolute;
  top: 0px;
  left: 0px;
}

.media--album:before {
  content: 'Open gallery';
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  position: absolute;
  z-index: 1;
  transform: rotate(-45deg);
  top: 20px;
  left: -16px;
  width: 100px;
  text-align: center;
  line-height: 15px;
}

.breadcrumb {
  padding: 0.5rem 1.2rem;
  background-color: #ffffff;
  border-radius: 3px;
}

.breadcrumb__item {
  display: inline-block;
  text-transform: uppercase;
}

.breadcrumb__item a:link,
.breadcrumb__item a:visited {
  text-decoration: underline;
}

.breadcrumb__item + li:before {
  content: "/\00a0";
  padding: 0 0.5rem;
}

.table {
  background-color: white;
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 1px solid #efefef;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #efefef;
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #efefef;
}

.table-bordered {
  border: 1px solid #efefef;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #efefef;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}

@media (min-width: 768px) {
  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.8);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.card {
  flex-basis: 100%;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.slick--showcase .card {
  display: flex !important;
}

.card--alpha {
  box-shadow: 0 1px 5px 0 rgba(57, 15, 47, 0.09);
}

.card--alpha:hover {
  box-shadow: 0 7px 30px 0 rgba(102, 67, 90, 0.42);
}

.card--delta:hover {
  box-shadow: 0 7px 30px 0 rgba(102, 67, 90, 0.42);
}

.card--bravo {
  border: 1px solid #868686;
  flex-direction: row;
}

.card--hotel {
  border-radius: 4px;
  border: 1px solid #9aa1b6;
  padding: 1.6rem;
}

.card--golf {
  box-shadow: 0 0 3px 0 rgba(38, 38, 38, 0.5);
}

.card--charlie {
  background-color: transparent;
}

.card--fit {
  align-self: flex-start;
}

.card__header {
  overflow: hidden;
  background-color: #122C0A;
  transition: height 0.3s;
  position: relative;
}

.card--hotel .card__header {
  background-color: transparent;
}

.card--bravo .card__header {
  flex-basis: 30%;
  display: flex;
}

.card--foxtrot .card__header {
  overflow: visible;
}

.card--charlie .card__header {
  border: 1px solid #efefef;
  border-bottom: 0;
}

.card--golf .card__header {
  background-color: #ffffff;
}

.card__header a {
  height: inherit;
  display: block;
}

.card--bravo .card__header a {
  width: 100%;
}

.card__actions {
  position: absolute;
  bottom: -21px;
  display: flex;
  right: 10px;
  z-index: 10;
}

.card__action {
  margin-left: 1rem;
}

.card__action--added svg {
  fill: #ff005c;
}

.card__thumb {
  display: block;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s;
}

.card--bravo .card__thumb {
  height: 100%;
}

.card--bg .card__thumb {
  padding-bottom: 70%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card--golf .card__thumb {
  display: block;
  max-width: 100%;
  max-height: 250px;
  margin: 0 auto;
  padding: 15px;
}

.card--img .card__thumb {
  display: block;
  max-width: 100%;
}

.card--bravo:hover .card__thumb,
.card--charlie:hover .card__thumb {
  opacity: 0.6;
  transform: scale(1.2);
}

.card__cta {
  position: absolute;
  opacity: 0;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  backface-visibility: none;
}

.card:hover .card__cta {
  top: 50%;
  opacity: 1;
}

.card--echo .card__cta {
  display: none;
}

.card--golf .card__text > *:not(:first-child) {
  margin-top: 1rem;
}

.card--hotel .card__text > * {
  margin-top: 1.6rem;
}

.card__body {
  position: relative;
  padding: 1.6rem;
  box-shadow: none;
  transition: box-shadow 200ms;
  flex: 1;
}

@media (min-width: 768px) {
  .card__body {
    padding: 3rem 2rem;
  }
}

.card--alpha .card__body {
  border-top: 1px solid #eeeeee;
}

.card--alpha:hover .card__body {
  box-shadow: inset 0px -10px 0px 0px #62AF6B;
}

.card--foxtrot .card__body {
  border: 1px solid rgba(18, 44, 10, 0.3);
}

@media (min-width: 768px) {
  .card--foxtrot .card__body {
    padding: 2rem;
  }
}

.card--bravo .card__body {
  flex-basis: 70%;
}

@media (min-width: 768px) {
  .card--golf .card__body {
    padding: 0 1.6rem 1.6rem;
  }
}

.card--hotel .card__body {
  padding: 0;
}

.card--charlie .card__body {
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-top: 0;
  left: 15px;
  width: calc(100% - 30px);
  top: 0px;
  flex: 1;
}

.card--charlie .card__body:before, .card--charlie .card__body:after {
  content: '';
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.card--charlie .card__body:before {
  left: -15px;
  border-width: 0 15px 10px 0;
  border-color: transparent #000000 transparent transparent;
}

.card--charlie .card__body:after {
  right: -15px;
  border-width: 10px 15px 0px 0;
  border-color: #000000 transparent  transparent transparent;
}

.card--delta .card__body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(18, 44, 10, 0), #122C0A);
}

.card--delta:hover .card__body {
  box-shadow: inset 0px -14px 0px 0px #62AF6B;
}

.card--echo .card__body {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  max-height: 60%;
  transition: bottom 350ms cubic-bezier(0.7, 0, 0.3, 1);
}

.card--echo .card__body:before {
  top: -10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.card--echo:hover .card__body {
  bottom: 0;
}

.card__body .card__anchor {
  display: flex;
  align-items: center;
}

.card__extra {
  margin-right: 1.5rem;
}

.card--delta .card__extra {
  color: #ffffff;
}

.card--alpha .card__title {
  font-family: "Raleway";
  font-weight: 700;
  color: #0e0e0e;
}

.card--delta .card__title {
  color: #ffffff;
}

.card--foxtrot .card__title {
  color: #62AF6B;
}

.card--golf .card__title {
  font-family: "Raleway";
  font-weight: 700;
  text-transform: uppercase;
}

.card--hotel .card__title {
  display: none;
}

.card__description {
  margin: 0;
}

.card--alpha .card__description {
  color: #0e0e0e;
}

.card--delta .card__description {
  color: #ffffff;
}

.card--foxtrot .card__description {
  color: #122C0A;
}

.card__footer {
  display: none;
  padding: 1rem 2rem;
  color: #ffffff;
  background: #737900;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.overlay--autohide {
  animation: cssAnimation 0s ease-in 5s forwards;
  animation-fill-mode: forwards;
  transition: all 200ms;
}

.overlay--admin {
  background: rgba(255, 255, 255, 0.9);
  top: 3px;
  left: 3px;
  bottom: 3px;
  right: 3px;
  z-index: 20;
  border: 1px dashed rgba(134, 134, 134, 0.3);
  cursor: pointer;
  opacity: 0;
  transition: all 250ms;
}

.admin .livedit:hover .overlay--admin {
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  opacity: 1;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.progressbar {
  display: flex;
  background-color: #efefef;
  border-radius: 3px;
  position: relative;
}

.progressbar--cart {
  margin-bottom: 30px;
  margin-top: 80px;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 25px 0;
  }
  to {
    background-position: 0 0;
  }
}

.progressbar__line {
  display: flex;
  height: 25px;
  width: 0;
  background-color: #122C0A;
  border-radius: 3px;
  transition: width 600ms;
  animation: progress-bar-stripes 1s linear infinite;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 25px 25px;
}

.progressbar__step {
  position: absolute;
  background-color: #efefef;
  bottom: 44px;
  margin: 0;
  padding: 2px 5px;
  border-radius: 2px;
  margin-left: -7px;
  text-align: center;
  line-height: 1.5rem;
}

.progressbar__step--1 {
  left: 0%;
}

.progressbar__step--2 {
  left: 33.333333%;
}

.progressbar__step--3 {
  right: 33.333333%;
  margin-right: -7px;
}

.progressbar__step--4 {
  right: 0;
  margin-right: -7px;
}

.progressbar__step::after {
  content: '';
  width: 25px;
  height: 25px;
  background: #efefef;
  position: absolute;
  bottom: -44px;
  border-radius: 100%;
  left: 0;
  /*
            content: '';
            border: solid #efefef;
            border-width: 6px;
            border-color: rgba(136, 183, 213, 0);
            border-top-color: #efefef;
            position: absolute;
            top: 100%;
            left: 3px;
            */
}

.progressbar__step::before {
  content: '';
  width: 1px;
  height: 25px;
  background: #efefef;
  position: absolute;
  left: 12px;
  top: 100%;
}

.progressbar__step--3::after {
  left: auto;
  right: 0;
}

.progressbar__step--4::after {
  left: auto;
  right: 0;
}

.progressbar__step--3::before {
  left: auto;
  right: 12px;
}

.progressbar__step--4::before {
  left: auto;
  right: 12px;
}

/* ----------------------------------------------------------- */
/* == tingle v0.12.0 */
/* ----------------------------------------------------------- */
.tingle-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  cursor: pointer;
  transition: transform .2s ease;
}

/* confirm and alerts
  -------------------------------------------------------------- */
.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

/* modal
  -------------------------------------------------------------- */
.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  position: fixed;
  top: 1rem;
  right: 3rem;
  z-index: 1000;
  padding: 0;
  width: 5rem;
  height: 5rem;
  border: none;
  background-color: transparent;
  color: #f0f0f0;
  font-family: monospace;
  line-height: 1;
  cursor: pointer;
  transition: color .3s ease;
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 90%;
  border-radius: 4px;
  background: #fff;
  opacity: 1;
  cursor: auto;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(0.8);
}

.tingle-modal-box__content {
  padding: 1.5rem;
}

.tingle-modal-box__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tingle-modal-box__row--spaced {
  padding: 0 1.5rem;
}

.tingle-modal-box__row .button {
  margin: 0;
}

.tingle-modal-box__footer,
.tingle-modal-box__header {
  padding: 1.5rem 3rem;
  margin: -1.5rem -1.5rem 0;
  width: auto;
  background-color: #efefef;
  cursor: auto;
}

.tingle-modal-box__footer::after {
  display: table;
  clear: both;
  content: "";
}

.tingle-modal-box__footer--sticky {
  position: fixed;
  bottom: -200px;
  /* TODO : find a better way */
  z-index: 10001;
  opacity: 1;
  transition: bottom .3s ease-in-out .3s;
}

/* state
  -------------------------------------------------------------- */
.tingle-enabled {
  overflow: hidden;
  height: 100%;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-enabled .tingle-content-wrapper {
  filter: blur(15px);
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  transform: scale(1);
}

.tingle-modal--overflow {
  overflow-y: scroll;
  padding-top: 8vh;
}

/* btn
  -------------------------------------------------------------- */
.tingle-btn {
  display: inline-block;
  margin: 0 .5rem;
  padding: 1rem 2rem;
  border: none;
  background-color: grey;
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  cursor: pointer;
  transition: background-color .4s ease;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

/* responsive
  -------------------------------------------------------------- */
@media (max-width: 540px) {
  .tingle-modal {
    top: 60px;
    display: block;
    width: 100%;
  }
  .tingle-modal-box {
    width: auto;
    border-radius: 0;
  }
  .tingle-modal-box__content {
    overflow-y: scroll;
  }
  .tingle-modal--noClose {
    top: 0;
  }
  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }
  .tingle-modal-box__footer .tingle-btn {
    display: block;
    float: none;
    margin-bottom: 1rem;
    width: 100%;
  }
  .tingle-modal__close {
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    border: none;
    background-color: #2c3e50;
    box-shadow: none;
    color: #fff;
    line-height: 55px;
  }
  .tingle-modal__closeLabel {
    display: inline-block;
    vertical-align: middle;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  .tingle-modal__closeIcon {
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
  }
}

.icon {
  width: 20px;
  height: 20px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  transition: fill 250ms;
}

.icon[class|="icon icon-ext"] {
  width: 32px;
  height: 32px;
}

.icon-ext-xls {
  fill: #267446;
}

.icon-ext-doc {
  fill: #375595;
}

.icon-ext-pdf {
  fill: #E9353C;
}

.icon-ext-zip {
  fill: #444444;
}

.icon-ext-jpg {
  fill: #ff8a00;
}

.overlay--admin .icon {
  width: 6rem;
  height: 6rem;
  fill: #868686;
  z-index: 40;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}

.button-icontext .icon {
  margin-right: 0.5rem;
}

.icon-info-with-circle {
  width: 13px;
  height: 13px;
}

.icon[class*="allergen-"] {
  width: 26px;
  height: 26px;
  color: #62AF6B;
  margin-right: 1.5rem;
  margin-top: .5rem;
}

.icon-social {
  width: 30px;
  height: 30px;
  color: #62AF6B;
  margin-right: 0.5rem;
}

.box {
  border-radius: 4px;
  padding: 1.5rem;
  margin: 1.5rem 0;
}

.box {
  margin: 0;
  padding: 2rem;
  border-bottom: 1px solid #efefef;
}

@media (min-width: 992px) {
  .box:first-child {
    margin-top: 0;
  }
  .box:last-child {
    margin-bottom: 0;
  }
}

.box--squared {
  border: 1px solid #efefef;
}

.box--simple {
  background: #ffffff;
}

.box--outlined {
  border: 1px solid ;
  background: #ffffff;
}

.listing__sidebar .box {
  flex: 1;
}

.slice {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.slice--landscape [class*="col-"] {
  position: static;
}

.slice--landscape .col-sm-12:first-child {
  min-height: 300px;
}

@media (min-width: 768px) {
  .slice--landscape .col-sm-12:first-child {
    min-height: auto;
  }
}

.slice--landscape .col-sm-12:last-child {
  margin-top: -50px;
}

@media (min-width: 768px) {
  .slice--landscape .col-sm-12:last-child {
    margin-top: 0;
  }
}

.slice--hero {
  height: 100vh;
}

.slice--landscape {
  max-width: 1500px;
}

.slice--gutter-xs {
  padding: 2rem 0;
}

.slice--gutter-s {
  padding: 5rem 0;
}

.slice--gutter-m {
  padding: 10rem 0;
}

.slice--gutter-l {
  padding: 15rem 0;
}

.slice--gutter-xl {
  padding: 18rem 0;
}

.slice--gutter-xxl {
  padding: 30rem 0;
}

@media (min-width: 768px) {
  .slice--size-xxl {
    min-height: 60rem;
  }
}

.slice__media, .slice__filter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.slice__media {
  z-index: 1;
}

.slice--fixed .slice__media {
  background-size: cover;
  background-position: center center;
}

.no-touch .slice--fixed .slice__media {
  background-attachment: fixed;
}

@media (min-width: 768px) {
  .slice--blurred .slice__media {
    filter: blur(5px);
  }
}

.slice--landscape .slice__media {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

@media (min-width: 768px) {
  .slice--landscape .slice__media {
    height: 100%;
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .slice--landscape-toleft .slice__media {
    background-size: contain;
    background-position: right center;
    right: 0;
    left: auto;
    right: 50%;
  }
}

.slice--landscape-toright .slice__media {
  background-size: contain;
}

@media (min-width: 768px) {
  .slice--landscape-toright .slice__media {
    background-position: left center;
    left: 50%;
  }
}

.slice__media video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slice__filter {
  z-index: 2;
}

.slice--gradient .slice__filter {
  background-image: linear-gradient(to bottom, rgba(18, 44, 10, 0.6), rgba(18, 44, 10, 0));
  background-color: transparent;
}

.slice--dark .slice__filter {
  background: rgba(0, 0, 0, 0.2);
}

.slice__text {
  position: relative;
  z-index: 3;
  margin: 15px auto;
}

.slice--landscape .slice__text {
  background: #fff;
  padding: 1.6rem;
}

@media (min-width: 768px) {
  .slice--landscape .slice__text {
    margin: -15px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.slice--hero .slice__text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  text-align: center;
}

@media (min-width: 768px) {
  .slice--hero .slice__text {
    width: calc(70% - 30px);
  }
}

.tag {
  padding: 3px 4px 2px;
  border-radius: 3px;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  line-height: 1;
}

.tag--dark {
  background: #122C0A;
  color: #ffffff;
}

.tag--light {
  background: #efefef;
  color: #122C0A;
}

.tag--danger {
  background: #F95C69;
  color: #ffffff;
}

.tag--warning {
  background: #efac1a;
  color: #122C0A;
}

.tag--success {
  background: #469b3f;
  color: #ffffff;
}

.collection__figure .tag {
  position: absolute;
  right: 15px;
  top: 5px;
  padding-right: 1px;
  padding-left: 15px;
}

.collection__figure .tag:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #ececec;
  position: absolute;
  left: 4px;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 100%;
}

.collection__figure .tag:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10.3px;
  border-color: transparent transparent transparent #000000;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -1px;
}

.tab {
  margin: 50px;
}

.tab__nav {
  list-style: none;
  display: flex;
  border-bottom: 1px solid #efefef;
}

.tab__nav-item {
  margin: 0 0 -1px 1rem;
}

.tab__nav-anchor {
  position: relative;
  display: block;
  padding: 1rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0;
}

.tab__nav-item--active .tab__nav-anchor {
  color: #868686;
  cursor: default;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-bottom-color: transparent;
}

.tab__panel {
  padding: 1.5rem;
}

.tab__panel:not(.tab__panel--active) {
  display: none;
}

.badge {
  width: 17px;
  height: 17px;
  background-color: #9FA299;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid #6c6f66;
  position: absolute;
  top: -4px;
  right: -7px;
  line-height: 0;
}

.badge:empty {
  display: none;
}

.masonry__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
}

@media (min-width: 768px) {
  .masonry__container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.masonry__item {
  display: flex;
  min-height: 320px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  grid-row: span 1;
  grid-column: span 1;
}

@media (min-width: 768px) {
  .masonry__item--size22 {
    grid-row: span 2;
    grid-column: span 2;
  }
}

@media (min-width: 768px) {
  .masonry__item--size12 {
    grid-row: span 2;
  }
}

@media (min-width: 768px) {
  .masonry__item--size21 {
    grid-column: span 2;
  }
}

@media (min-width: 768px) {
  .masonry__item--size31 {
    grid-column: span 3;
  }
}

.masonry__anchor {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.masonry__anchor--null {
  pointer-events: none;
}

.masonry__info {
  background: white;
  padding: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.masonry__subtitle {
  line-height: 1.6rem;
}

.rating {
  display: flex;
}

.rating__wrap {
  display: flex;
  align-items: center;
}

.rating__item {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #9FA299;
}

.rating[data-rate='1'] .rating__item:nth-child(1) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='2'] .rating__item:nth-child(2) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='3'] .rating__item:nth-child(3) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='4'] .rating__item:nth-child(4) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='5'] .rating__item:nth-child(5) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='6'] .rating__item:nth-child(6) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='7'] .rating__item:nth-child(7) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='8'] .rating__item:nth-child(8) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='9'] .rating__item:nth-child(9) ~ .rating__item {
  background: #f5f2f4;
}

.rating[data-rate='10'] .rating__item:nth-child(10) ~ .rating__item {
  background: #f5f2f4;
}

.rating__item:not(:first-child) {
  margin-left: 8px;
}

.rating__label {
  margin-left: 8px;
}

.courseDetail {
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .courseDetail {
    margin-top: -240px;
  }
}

.courseDetail__media {
  display: flex;
  flex-basis: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.courseDetail__intro {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

@media (min-width: 768px) {
  .courseDetail__intro {
    min-height: 385px;
  }
}

.courseDetail__intro-top {
  display: flex;
  flex: 1;
}

@media (min-width: 768px) {
  .courseDetail__intro-top {
    padding: 36px;
  }
}

.courseDetail__intro-bottom {
  padding: 36px 0;
}

@media (min-width: 768px) {
  .courseDetail__intro-bottom {
    padding: 0 72px 36px;
  }
}

.courseDetail__date {
  flex-shrink: 0;
  margin-right: 24px;
}

@media (min-width: 768px) {
  .courseDetail__extraInfo {
    padding-left: 140px;
  }
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.display-inlineblock {
  display: inline-block !important;
}

@media (min-width: 768px) {
  .hidable__dk {
    display: none !important;
  }
}

.hidable__mb {
  display: none !important;
}

@media (min-width: 768px) {
  .hidable__mb {
    display: flex !important;
  }
}

.transform-uppercase {
  text-transform: uppercase !important;
}

.transform-italic {
  font-style: italic !important;
}

.transform-through {
  text-decoration: line-through !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.clearfix:after,
.livedit:after {
  content: "";
  display: table;
  clear: both;
}

.u-border-top {
  border-top: 1px solid #737900;
}

.u-padding-vertical-small {
  padding: 1.5rem 0 !important;
}

.u-padding-vertical-medium {
  padding: 3rem 0 !important;
}

.u-padding-vertical-big {
  padding: 8rem 0 !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-padding-bottom-large {
  padding-bottom: 6rem !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-margin-small {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-bottom-micro {
  margin-bottom: 0.5rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 3rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-top-small {
  margin-top: 1.5rem !important;
}

.u-margin-top-medium {
  margin-top: 3rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-margin-right-small {
  margin-right: 1.5rem !important;
}
