.accordion {
  $accordion-root: &;

  //wrap di tutti i pannelli
  &__group {
    padding: 1rem 0;

    // Group in variante simple
    #{$accordion-root}--simple & {
      border-bottom: 1px dotted map-get($color-map, primary);

      &:last-child {
        border: 0;
      }
    }

  }

  //variante simple
  &--simple {}

  // header variante colorata
  &--deco {}

  // header
  &__heading {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    //Header in variante Simple
    #{$accordion-root}--simple & {
      padding: 1.1rem 0;
    }

    //Header in variante deco
    #{$accordion-root}--deco & {
      background: rgba(map-get($color-map, gallery), .80);
      margin-bottom: 1px;
      transition: background 250ms;
      padding: 1.1rem;
    }

    // Se sono in un pannello attivo
    #{$accordion-root}__group--active & {

      // quando sono in una variante deco
      #{$accordion-root}--deco & {
        background: darken(rgba(map-get($color-map, gallery), .80), 10%);
      }
    }

  }

  // titoli
  &__title {
    @extend %size-base;
    text-transform: uppercase;
    margin: 0 1.1rem 0 0;
  }

  // elemento che racchiude i contenuti
  &__collapse-inner {
    display: none;

    //Inner in variante simple
    #{$accordion-root}--simple & {
      padding: 0 0 1.1rem;
    }

    //Inner in variante simple
    #{$accordion-root}--deco & {
      background: rgba(map-get($color-map, gallery), .50);
      padding: 1.1rem 2rem;
    }
  }

  // elemento testuale principale
  &__infoText {
    margin: 0 1.5rem 0 0;
    @extend %size-small;
  }

  // elemento freccia che definisce lo stato
  &__arrow {
    transition: transform 250ms;

    // nel pannello attivo
    #{$accordion-root}__group--active & {
      transform: rotate(180deg);
    }
  }

}
